export function useClickOutside(element, fn) {
    if (!import.meta.client) {
        return;
    }

    // don't add event listener if fn is not type of func
    if (typeof fn !== 'function') {
        return;
    }

    /**
     * Event handler
     *
     * @param {Event} event
     */
    function handler(event) {
        // unref element
        const el = unref(element);

        if (!el) {
            return;
        }

        const { target } = event;

        if (el !== target && !el.contains(target)) {
            fn(event);
        }
    }

    //
    onMounted(() => {
        document.addEventListener('click', handler);
    });

    onBeforeUnmount(() => {
        document.removeEventListener('click', handler);
    });
}
